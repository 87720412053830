// InfoComponent.js
import React from 'react';
import { Typography, Paper } from '@mui/material';

const InfoFreeLoginAccount = () => {
    return (
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', backgroundColor: '#f5f5f5' }}>
            <Typography variant="body1">
                Tip: You can log in as <strong>admin9</strong> with the password <strong>adminpassword</strong>.
            </Typography>
        </Paper>
    );
};

export default InfoFreeLoginAccount;