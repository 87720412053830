import React, { createContext, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSession, postLogin, getLogout } from '../apiCalls';


const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(() => {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });
    const [loading, setLoading] = useState(true);

    const handleUserAndStorage = useCallback((userFromApi) => {
        if (userFromApi) {
            setUser(userFromApi);
            localStorage.setItem('user', JSON.stringify(userFromApi));
        } else {
            setUser(null);
            localStorage.removeItem('user');
            navigate('/login', { replace: true });
        }
    }, [navigate]);


    const fetchUser = useCallback(async (isWithCredentials) => {

        try {
            // Check if "session_exists" cookie exists
            const sessionExists = document.cookie.split('; ').find(row => row.startsWith('session_exists'));
            if (!sessionExists) {
                handleUserAndStorage(null);
                setLoading(false);
                return;
            }

            const data = await getSession()
            console.debug('fetchUser data', data);
            handleUserAndStorage(data.user || null);

        } catch (error) {
            if (error.response?.status === 401) {
                handleUserAndStorage(null);
            } else {
                console.error('Error fetching user:', error);
            }
        } finally {
            setLoading(false);
        }
    }, [handleUserAndStorage]);


    const login = useCallback(async (username, password) => {
        try {
            const data = await postLogin(username, password)
            // console.debug('login api after data', data);
            const { user, message } = data;

            handleUserAndStorage(user || null);
            return data;
        } catch (error) {
            console.debug('login api after error', error);
            if (error.response?.status === 401) {
                handleUserAndStorage(null);
            } else {
                console.error("Login error: ", error.response ? error.response.data.message : error.message);
                // throw error; // Rethrow the error after logging
            }
            throw error;
        }
    }, [handleUserAndStorage]);

    const logout = useCallback(async () => {
        try {
            const data = await getLogout()
            handleUserAndStorage(null)
            console.log('Logged out successfully');
            return data;
        } catch (error) {
            if (error.response?.status === 401) {
                handleUserAndStorage(null);
            } else {
                console.error('Logout failed', error);
            }
            throw error;
        } finally {
            // Fetch user after logout to update the state regardless of whether the logout succeeded or failed.
            await fetchUser(true);
        }
    }, [handleUserAndStorage, fetchUser]);

    return (
        <AuthContext.Provider value={{ user, setUser, login, logout, loading, handleUserAndStorage }}>
            {children}
        </AuthContext.Provider>
    );
}