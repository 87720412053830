const menuItems = [
    { title: 'Home', path: '/', roles: ['guest'], comp: 'Home', compPath: './pages/Home' },
    { title: 'Namerané teploty - grafy', path: '/temp', roles: ['manager', 'admin'], menu: 'Meranie teploty', comp: 'ChartTemperatures', compPath: './pages/ChartTemperatures', descr: 'Pre zvolený čas grafy + ukladanie do súborov .xlsx, .pdf, .json, csv' },
    { title: 'Cron job merania teploty', path: '/temp-cron-job', roles: ['manager', 'admin'], menu: 'Meranie teploty', comp: 'TempCronJob', compPath: './pages/TempCronJob', descr: 'Nastavenia pravidelného merania teploty' },
    { title: 'Extra meranie teploty', path: '/temp-custom', roles: ['manager', 'admin'], menu: 'Meranie teploty', comp: 'CustomTemperatureMeasurementLimitedInIntervals', compPath: './pages/CustomTemperatureMeasurementLimitedInIntervals', descr: 'Okamžité meranie teploty (real-time, websocket)' },
    { title: 'Download - existujúce súbory merania teploty', path: '/temp-download', roles: ['manager', 'admin'], menu: 'Meranie teploty', comp: 'DownloadTemp1MultipleFiles', compPath: './pages/DownloadTemp1MultipleFiles', descr: 'Stiahni existujúce súbory s nameranými dátami.' },
    { title: 'Cron job generovania Excel súborov', path: '/excel-cron-job', roles: ['manager', 'admin'], menu: 'Meranie teploty', comp: 'ExcelCronJob', compPath: './pages/ExcelCronJob', descr: 'Nastavenia pravidelného generovania Excel súborov z dát v databáze.' },
    { title: 'Upload Excel súborov z merania', path: '/excel-view', roles: ['manager', 'admin'], menu: 'Meranie teploty', comp: 'ExcelViewFile', compPath: './pages/ExcelViewFile', descr: 'Pozri si už u seba stiahnutý Excel súbor z merania.' },
    { title: 'Prihlásenie', path: '/login', roles: ['guest'], menu: 'user', comp: 'Login', compPath: './pages/Login' },
    { title: 'Profil užívateľa', path: '/user-profile', roles: ['user'], menu: 'user', comp: 'UserProfile', compPath: './pages/UserProfile' },
    { title: 'Registrácia užívateľa', path: '/register', roles: ['admin'], menu: 'user', comp: 'RegistrationByAdmin', compPath: './pages/RegistrationByAdmin' },
    { title: 'Užívatelia', path: '/users', roles: ['admin'], menu: 'user', comp: 'Users', compPath: './pages/Users' },
];

export default menuItems;