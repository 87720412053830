import { useState, useCallback } from 'react';

export function useSnackbarAlert(initialSeverity = 'info') {
    const [snackbarOpened, setSnackbarOpened] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState(initialSeverity);

    const openSnackbar = useCallback((message, severity = initialSeverity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpened(true);
    }, [initialSeverity]);

    const closeSnackbar = useCallback(() => {
        setSnackbarOpened(false);
    }, []);

    return { snackbarOpened, snackbarMessage, snackbarSeverity, openSnackbar, closeSnackbar };
}