import React, { useMemo } from 'react';
import Login from '../pages/Login';
import { useAuth } from './AuthContext';

const roleChecks = {
    user: (user) => user && (user.role === 'user' || user.role === 'manager' || user.role === 'admin'),
    manager: (user) => user && (user.role === 'manager' || user.role === 'admin'),
    admin: (user) => user && user.role === 'admin',
};

const AuthenticatedRoute = ({ children, role, ...rest }) => {
    const { user } = useAuth();
    const hasRequiredRole = useMemo(() => roleChecks[role] ? roleChecks[role](user) : false, [role, user]);

    return hasRequiredRole ? children : <Login />;
};

export default React.memo(AuthenticatedRoute);