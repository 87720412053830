import React, { useEffect, useState } from 'react';

function CountdownDiv({ initialCount, onCountdownEnd }) {
    const [count, setCount] = useState(initialCount);

    useEffect(() => {
        if (count <= 0) {
            onCountdownEnd();
            return;
        }
        const intervalId = setInterval(() => {
            setCount((currentCount) => currentCount - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [count, onCountdownEnd]);

    return <div>{count}s</div>;
}

export default CountdownDiv;