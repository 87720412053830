import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            // main: '#556cd6',
            main: '#0E8388',
        },
        secondary: {
            // main: '#19857b',
            main: '#CBE4DE',
        },
        warning: {
            main: "#2E4F4F"
        },
        info: {
            main: "#2C3333"
        },
    },
    typography: {
        // Use a system font over the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(',')

    },
    spacing: (multiplier = 1) => `${0.5 * multiplier}rem`,
})
const themeWithOverrides = createTheme(theme, {
    typography: {
        h5: {
            fontSize: '1.5rem',
            '@media (min-width:600px)': {
                fontSize: '1.75rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '2rem',
            },
        },
        // Add more typography variants as needed
    },
    components: {
        MuiBox: {
            styleOverrides: {
                root: {
                    // Base styles for Paper
                    padding: theme.spacing(2),
                    [theme.breakpoints.up('sm')]: {
                        padding: theme.spacing(3), // larger padding on sm and up
                    },
                    [theme.breakpoints.up('md')]: {
                        padding: theme.spacing(4), // even larger padding on md and up
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    // Base styles for Paper
                    padding: theme.spacing(1),
                    [theme.breakpoints.up('sm')]: {
                        padding: theme.spacing(2), // larger padding on sm and up
                    },
                    [theme.breakpoints.up('md')]: {
                        padding: theme.spacing(3), // even larger padding on md and up
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    // Base styles for Typography
                    [theme.breakpoints.up('xs')]: {
                        fontSize: '0.875rem', // smaller font size on sm and down
                    },
                    [theme.breakpoints.up('sm')]: {
                        fontSize: '0.875rem', // smaller font size on sm and down
                    },
                    [theme.breakpoints.up('md')]: {
                        fontSize: '1rem', // default font size on md and up
                    },
                    [theme.breakpoints.up('lg')]: {
                        fontSize: '1.125rem', // larger font size on lg and up
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    // Define default styles here
                    padding: theme.spacing(1, 2),
                    // fontSize: '0.75rem',
                    // Define responsive styles
                    [theme.breakpoints.up('xs')]: {
                        padding: theme.spacing(1, 3),
                        fontSize: '0.75rem',
                    },
                    [theme.breakpoints.up('sm')]: {
                        padding: theme.spacing(1, 3),
                        fontSize: '0.875rem',
                    },
                    [theme.breakpoints.up('md')]: {
                        padding: theme.spacing(1, 4),
                        fontSize: '1rem',
                    },
                    // [theme.breakpoints.up('lg')]: {
                    //     padding: theme.spacing(1, 5),
                    // },
                    // You can also adjust font size, border radius, etc.

                    // [theme.breakpoints.up('md')]: {
                    //     fontSize: '1rem',
                    // },
                    // And so on for other properties...
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    // Default style
                    minWidth: 120,
                    // padding: theme.spacing(1, 0.5),
                    fontSize: '0.75rem',
                    // Responsive styles
                    [theme.breakpoints.up('xs')]: {
                        // padding: theme.spacing(1, 0.5),
                        padding: theme.spacing(0),
                        minWidth: 150,
                        fontSize: '0.75rem',
                    },
                    [theme.breakpoints.up('sm')]: {
                        // padding: theme.spacing(2, 2),
                        padding: theme.spacing(0),
                        minWidth: 150,
                        fontSize: '0.875rem',
                    },
                    [theme.breakpoints.up('md')]: {
                        // padding: theme.spacing(1, 4),
                        padding: theme.spacing(0, 0),
                        minWidth: 200,
                        fontSize: '1rem',
                    },
                    // ... You can add more styles for other breakpoints or other properties

                    // [theme.breakpoints.up('sm')]: {
                    // },
                    // [theme.breakpoints.up('md')]: {
                    // },
                    // [theme.breakpoints.up('lg')]: {
                    //     padding: theme.spacing(1, 5),
                    // },
                    // You can also adjust font size, border radius, etc.
                    // [theme.breakpoints.down('xs')]: {
                    //     fontSize: '0.75rem',
                    // },
                    // [theme.breakpoints.up('sm')]: {
                    //     fontSize: '0.875rem',
                    // },
                    // [theme.breakpoints.up('md')]: {
                    //     fontSize: '1rem',
                    // },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    // Default style
                    minHeight: '36px', // '48px', // Adjust the default height if needed
                    fontSize: '0.75rem',
                    // Responsive styles
                    [theme.breakpoints.up('sm')]: {
                        minHeight: '54px', // Height for sm breakpoint and up
                    },
                    [theme.breakpoints.up('md')]: {
                        minHeight: '60px', // Height for md breakpoint and up
                    },
                    // You can adjust other properties like padding or font size as well
                    // For example:
                    [theme.breakpoints.down('xs')]: {
                        fontSize: '0.75rem', // Smaller font size for xs screens
                    },
                    [theme.breakpoints.up('sm')]: {
                        fontSize: '0.875rem', // Larger font size for sm screens and up
                    },
                    // More styles...
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // Default styles
                    // width: '100%', // Full width by default
                    // fontSize: '0.75rem',
                    // margin: theme.spacing(0.5),
                    // Responsive styles
                    [theme.breakpoints.up('xs')]: {
                        width: '100%', // Auto width on larger screens to avoid stretching
                        fontSize: '0.875rem',
                        mx: theme.spacing(0),
                        my: '10px 0'
                    },
                    [theme.breakpoints.up('sm')]: {
                        width: 'auto', // Auto width on larger screens to avoid stretching
                        fontSize: '0.875rem',
                        margin: theme.spacing(1),
                    },
                    // Adjust margin or padding if needed
                    [theme.breakpoints.up('md')]: {
                        margin: theme.spacing(2),
                    },
                    // ... Add more styles for other breakpoints or properties as needed
                },
            },
        },
        // ... (other component overrides)
    },
});


export { themeWithOverrides as theme };