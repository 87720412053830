import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Grid, Container, Paper, Typography } from '@mui/material';
import { useAuth } from '../components/AuthContext';
import SnackbarAlert from '../components/SnackbarAlert';
import { useSnackbarAlert } from '../hooks/useSnackbarAlert';
import InfoFreeLoginAccount from '../components/InfoFreeLoginAccount';

const Login = () => {
    const navigate = useNavigate();
    const { login, user } = useAuth();
    const usernameRef = useRef('');
    const passwordRef = useRef('');
    const [error, setError] = useState('');
    const { snackbarOpened, snackbarMessage, snackbarSeverity, openSnackbar, closeSnackbar } = useSnackbarAlert();

    useEffect(() => {
        if (!snackbarOpened && snackbarSeverity === 'success') {
            navigate('/');
        }
    }, [snackbarOpened, snackbarSeverity, navigate]);

    const handleLogin = useCallback(async (event) => {
        event.preventDefault();
        try {
            const data = await login(usernameRef.current.value, passwordRef.current.value);
            const { message, user } = data;
            openSnackbar(`${message} - ${user.username}`, 'success');
            // navigate('/');

        } catch (error) {
            console.debug('Login after error', error);
            let errMessageDefault = "Chyba pri prihlásení. Skontroluj si username a heslo.";
            const errMessageReturned = error.response?.data?.message
            const errMessageInfoReturned = error.response?.data?.info
            const errMessageResult = errMessageInfoReturned ? `${errMessageReturned}: ${errMessageInfoReturned}` : errMessageReturned ? errMessageReturned : errMessageDefault;
            setError(errMessageResult);
            openSnackbar(errMessageResult, 'error');
        }
    }, [login, openSnackbar]);

    return (
        <Container component="main" maxWidth="xs">
            {!user && <InfoFreeLoginAccount />}
            <Paper elevation={6} style={{ marginTop: '2rem', padding: '1rem' }}>
                <Typography component="h1" variant="h5" style={{ textAlign: 'center', marginBottom: '1rem' }}>
                    {user?.username ? `Si prihlásený ako ${user?.username}` : "Prihlásenie"}
                </Typography>
                <form onSubmit={handleLogin}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <TextField
                                label="Username"
                                defaultValue={usernameRef.current}
                                onChange={(e) => usernameRef.current = e.target}
                                variant="outlined"
                                fullWidth
                                required
                                autoFocus
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Password"
                                type="password"
                                defaultValue={passwordRef.current}
                                onChange={(e) => passwordRef.current = e.target}
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Log In
                            </Button>
                        </Grid>
                    </Grid>
                    {error && (
                        <Typography color="error" variant="body2">
                            {error}
                        </Typography>
                    )}
                </form>
            </Paper>
            {snackbarOpened && <SnackbarAlert
                autoHideDuration={snackbarSeverity === 'success' ? 2000 : 6000}
                open={snackbarOpened}
                handleClose={closeSnackbar}
                severity={snackbarSeverity}
                message={snackbarMessage}
            />}
        </Container>
    );
};

export default Login;