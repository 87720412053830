import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { skSK } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/sk';
import menuItems from './routes/menuItems';
import DrawerOpenProvider from './components/DrawerOpenProvider';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import SnackbarWakingUpServerWithCountdown from './components/SnackbarWakingUpServerWithCountdown';

const slovakLocale = skSK.components.MuiLocalizationProvider.defaultProps.localeText;
const API_URL = process.env.REACT_APP_SERVER_URL;

const NavigationMenu = lazy(() => import('./components/NavigationMenu'));

function App() {

  return (<>
    <SnackbarWakingUpServerWithCountdown serverUrlQuickApi={`${API_URL}/api/ping`} wakingTimeInSeconds={50} />
    <AuthProvider>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="sk"
        // Define the translations to have the right placeholders (e.g. `JJJJ` for the year).
        localeText={slovakLocale}>
        <DrawerOpenProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <NavigationMenu />
            <Routes>
              {menuItems.map((item, index) => {

                const Component = lazy(() =>
                  import(`${item.compPath}`) // Adjusted to handle leading slash
                );
                // Generate the route
                let routeElement = <Route key={index} path={item.path} element={<Component />} />;

                // Decide if the route needs authentication based on the roles
                const needsAuthentication = item.roles && item.roles.length > 0 && !item.roles.includes('guest');
                // If the route needs authentication, wrap it with AuthenticatedRouteCheckedOnExpiration
                if (needsAuthentication) {
                  routeElement = (
                    <Route
                      key={index}
                      path={item.path}
                      element={
                        
                        <AuthenticatedRoute role={item.roles[0]}>
                          <Component />
                        </AuthenticatedRoute>
                      }
                    />
                  );
                }

                return routeElement;
              })}
              {/* Wildcard route to handle non-existing routes */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </DrawerOpenProvider>
      </LocalizationProvider>
    </AuthProvider>
  </>
  );
}

export default App;

