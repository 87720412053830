import React, { useEffect, useState } from 'react';
import { Snackbar, CircularProgress, Button } from '@mui/material';
import CountdownDiv from './CountdownDiv'; // Ensure this path is correct

function SnackbarWakingUpServerWithCountdown({ serverUrlQuickApi, wakingTimeInSeconds = 50, repeatAttempts = 2, onServerReady = () => console.log('Server is ready') }) {
    const [serverWakingUp, setServerWakingUp] = useState(true);
    const [attempt, setAttempt] = useState(1);
    const [showRetry, setShowRetry] = useState(false);

    useEffect(() => {
        const fetchServer = async () => {
            try {
                const response = await fetch(serverUrlQuickApi);
                if (response.ok) {
                    console.log('Server is ready');
                    setServerWakingUp(false);
                    if (onServerReady) onServerReady();
                    return true; // Indicate success
                }
            } catch (error) {
                console.error('Error waking up the server:', error);
            }
            return false; // Indicate failure
        };

        const attemptToWakeServer = async () => {
            const success = await fetchServer();
            if (!success && attempt < repeatAttempts) {
                // Prepare for next attempt
                setAttempt(attempt + 1);
            } else if (!success) {
                // Max attempts reached
                setServerWakingUp(false);
                setShowRetry(true);
            }
        };

        if (serverWakingUp) {
            attemptToWakeServer();
        }
    }, [serverUrlQuickApi, onServerReady, attempt, wakingTimeInSeconds, repeatAttempts, serverWakingUp]);

    const handleRetry = () => {
        setAttempt(1);
        setServerWakingUp(true);
        setShowRetry(false);
    };

    const handleCountdownEnd = () => {
        if (attempt < repeatAttempts) {
            setAttempt(attempt + 1);
        } else {
            setServerWakingUp(false);
            setShowRetry(true);
        }
    };

    return (
        <>
            {serverWakingUp && (
                <Snackbar
                    open={true}
                    message={`Attempt ${attempt}: Server is waking up, please wait... `}
                    action={
                        <>
                            <CountdownDiv initialCount={wakingTimeInSeconds} onCountdownEnd={handleCountdownEnd} />
                            <CircularProgress size={24} />
                        </>
                    }
                />
            )}
            {!serverWakingUp && showRetry && (
                <Snackbar
                    open={true}
                    message="Failed to wake up the server. Please try again later."
                    action={
                        <Button color="secondary" size="small" onClick={handleRetry}>
                            Retry to connect to api server
                        </Button>
                    }
                />
            )}
        </>
    );
}

export default SnackbarWakingUpServerWithCountdown;