import axios from 'axios';
import {
    LIST_GENERATED_FILES_URL, REFRESH_TOKEN_API, REGISTER_USER_API, USER_CHANGE_PASSWORD_API, USER_PROFILE_API, USER_API, USERS_API,
    USER_LOGOUT_API, USER_LOGIN_API, USER_SESSION_API, DOWNLOAD_FILE_API, DOWNLOAD_EXCEL_BY_TIME_API, DOWNLOAD_TEMP1_MONTH_API,
    CUSTOM_INTERVALS_TOTAL_TIME_TEMP_API, STOP_CUSTOM_INTERVALS_TOTAL_TIME_TEMP_API, START_END_TEMP_API, USER_AUTH_STATUS_API,
    SHOULD_ONSTART_CREATE_EXCEL_JOB_API, STOP_JOB_CREATE_EXCEL_API, START_JOB_CREATE_EXCEL_API, JOB_CREATE_EXCEL_API, SHOULD_ONSTART_TEMP1_JOB_API,
    TEMP1_API, TEMP1_JOB_API, START_TEMP1_JOB_API, STOP_TEMP1_JOB_API,
    TEMP1_SETTINGS_API, TEMP1_SETTINGS_UPDATE_API, CREATE_MULTIPLE_FILES_WITH_TEMPS_FOR_TIME_INTERVAL_API
} from './constants';

export const postRegisterUser = async (userForm) => {
    const response = await axios.post(REGISTER_USER_API,
        userForm,
        { withCredentials: true });
    return response.data;
}

export const postChangePassword = async (oldPassword, newPassword) => {
    const response = await axios.post(USER_CHANGE_PASSWORD_API,
        {
            oldPassword,
            newPassword
        },
        { withCredentials: true });
    return response.data;
}

export const getUserProfile = async () => {
    const response = await axios.get(USER_PROFILE_API,
        { withCredentials: true });
    return response.data;
}

export const updateUser = async (user) => {
    const response = await axios.put(`${USERS_API}/${user.id}/update`,
        user,
        { withCredentials: true });
    return response.data;
}

export const deleteUser = async (id) => {
    const response = await axios.delete(`${USERS_API}/${id}`,
        { withCredentials: true });
    return response.data;
}

export const getUsers = async () => {
    const response = await axios.get(
        USERS_API,
        { withCredentials: true });
    return response.data;
}

export const getLogout = async () => {
    const response = await axios.get(USER_LOGOUT_API,
        { withCredentials: true });
    return response.data;
}

export const postLogin = async (username, password) => {
    try {
        const response = await axios.post(USER_LOGIN_API, {
            username,
            password
        }, { withCredentials: true });

        // Assuming the response structure is known and consistent
        if (response.data && response.status === 200) {
            return response.data; // Contains message and user info
        } else {
            // Handle unexpected response structure
            console.error('postLogin Unexpected response structure:', response);
            throw new Error('Login failed due to unexpected response structure.');
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('postLogin error:', error.response.data);
            throw new Error(error.response.data || 'Error logging in.');
        } else if (error.request) {
            // The request was made but no response was received
            console.error('postLogin No response received:', error.request);
            throw new Error('postLogin No response received from the server.');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('postLogin Error:', error.message);
            throw new Error('postLogin Error setting up login request.');
        }
    }
};

export const getSession = async () => {
    const response = await axios.get(USER_SESSION_API,
        { withCredentials: true });
    return response.data;
}

export const getDownloadFileBlob = async (filename) => {
    const response = await axios.get(`${DOWNLOAD_FILE_API}/${filename}`,
        { withCredentials: true, responseType: 'blob' });
    return response.data;
}
export const postCreateJsonCsvXlsxPdfFilesForIntervalAndReceiveFilenames = async (startDateTime, endDateTime) => {
    const response = await axios.post(CREATE_MULTIPLE_FILES_WITH_TEMPS_FOR_TIME_INTERVAL_API,
        {
            startTime: startDateTime,
            endTime: endDateTime,
        },
        { withCredentials: true }
    );
    return response.data;
}

export const postDownloadTemp1SelectedExcel = async (startDateTime, endDateTime) => {
    const response = await axios.post(DOWNLOAD_EXCEL_BY_TIME_API,
        {
            startTime: startDateTime,
            endTime: endDateTime,
        },
        { withCredentials: true, responseType: 'arraybuffer' });
    return response.data;
}

export const getTemp1MonthDownloadBuffer = async (year, month, fileFormat) => {
    const response = await axios.get(`${DOWNLOAD_TEMP1_MONTH_API}/${year}/${month}/${fileFormat}`,
        { withCredentials: true, responseType: 'arraybuffer' });
    return response.data;
}


export const postTempCustomIntervalsTotalTime = async (intervalTime, totalTime) => {
    const response = await axios.post(CUSTOM_INTERVALS_TOTAL_TIME_TEMP_API,
        {
            intervalTimeReadTemperatureMinutes: Number(intervalTime),
            totalReadingTimeMin: Number(totalTime),
        },
        { withCredentials: true });
    return response.data;
}
export const postTempCustomIntervalsTotalTimeStop = async () => {
    const response = await axios.post(STOP_CUSTOM_INTERVALS_TOTAL_TIME_TEMP_API,
        {},
        { withCredentials: true });
    return response.data;
}

export const getTempBetweenStartAndEndTime = async (startDateTime, endDateTime) => {
    const response = await axios.get(TEMP1_API,
        {
            params: {
                startTime: startDateTime,
                endTime: endDateTime,
            }, withCredentials: true
        });
    return response.data;
}

export const getUserAuthStatus = async () => {
    try {
        const response = await axios.get(USER_AUTH_STATUS_API, {
            withCredentials: true,
            timeout: 5000, // Timeout after 5000ms or 5 seconds
        });

        // Check if the response status code is 200 (OK)
        if (response.status === 200) {
            return response.data;
        } else {
            // Log or handle non-200 responses as needed
            console.error(`getUserAuthStatus: Received non-200 response status: ${response.status}`);
            return null; // Or handle as appropriate
        }
    } catch (error) {
        // Log or handle errors as needed
        console.error(`getUserAuthStatus: Error fetching user auth status: ${error.message}`);
        return null; // Or handle as appropriate
    }
};

export const getOnStartTemp1Job = async () => {
    const response = await axios.get(SHOULD_ONSTART_TEMP1_JOB_API, { withCredentials: true });
    return response.data;
}

export const postOnStartTemp1Job = async (startsJobWithServer) => {
    const response = await axios.post(SHOULD_ONSTART_TEMP1_JOB_API,
        { startsJobWithServer },
        { withCredentials: true });
    return response.data;
}

export const getOnStartExcelCreateJob = async () => {
    const response = await axios.get(SHOULD_ONSTART_CREATE_EXCEL_JOB_API, { withCredentials: true });
    return response.data;
}

export const postOnStartExcelCreateJob = async (startsJobWithServer) => {
    const response = await axios.post(SHOULD_ONSTART_CREATE_EXCEL_JOB_API,
        { startsJobWithServer },
        { withCredentials: true });
    return response.data;
}

export const getTemp1JobStatus = async () => {
    const response = await axios.get(TEMP1_JOB_API,
        { withCredentials: true });
    return response.data;
}

export const getTemp1Settings = async () => {
    const response = await axios.get(TEMP1_SETTINGS_API,
        { withCredentials: true });
    return response.data;
}

export const postTemp1Settings = async (temp1Settings) => {
    const response = await axios.post(TEMP1_SETTINGS_UPDATE_API,
        { temp1TemperatureSettings: temp1Settings },
        { withCredentials: true });
    return response.data;
}

export const postStartTemp1Job = async () => {
    const response = await axios.post(START_TEMP1_JOB_API,
        {},
        { withCredentials: true });
    return response.data;
}

export const postStopTemp1Job = async () => {
    const response = await axios.post(STOP_TEMP1_JOB_API,
        {},
        { withCredentials: true });
    return response.data;
}

export const getJobExcelCreateStatus = async () => {
    const response = await axios.get(JOB_CREATE_EXCEL_API,
        { withCredentials: true });
    return response.data;
}

export const postStartJobExcelCreate = async () => {
    const response = await axios.post(START_JOB_CREATE_EXCEL_API,
        { withCredentials: true });
    return response.data;

}

export const postStopJobExcelCreate = async () => {
    const response = await axios.post(STOP_JOB_CREATE_EXCEL_API,
        { withCredentials: true });
    return response.data;

}
export const postRefreshToken = async () => {
    // Example implementation, modify as necessary for your API
    const response = await axios.post(REFRESH_TOKEN_API,
        { withCredentials: true }
    ); // If cookies are used to manage the refresh token

    return response.data;
};

export const listGeneratedFiles = async () => {
    const response = await axios.get(LIST_GENERATED_FILES_URL,
        { withCredentials: true }
    );
    return response.data

};
