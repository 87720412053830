// DrawerContext.js
import React, { useState, createContext, useContext } from 'react';

const DrawerOpenContext = createContext();
export const useDrawerOpen = () => useContext(DrawerOpenContext);

const DrawerOpenProvider = ({ children }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <DrawerOpenContext.Provider value={{ isDrawerOpen, setIsDrawerOpen }}>
            {children}
        </DrawerOpenContext.Provider>
    )
}

export default DrawerOpenProvider


