export const API_URL = `${process.env.REACT_APP_SERVER_URL}/api`;
export const TEMP1_API = `${API_URL}/temp1`;
export const FILES_API = `${API_URL}/files`;
export const CRON_API = `${API_URL}/crons`;

export const TEMP1_SETTINGS_API = `${TEMP1_API}/settings`;
export const TEMP1_SETTINGS_UPDATE_API = `${TEMP1_API}/settings/update`;
export const SHOULD_ONSTART_TEMP1_JOB_API = `${TEMP1_API}/jobs/start-on-init`;
export const TEMP1_JOB_API = `${TEMP1_API}/jobs/status`;
export const START_TEMP1_JOB_API = `${TEMP1_API}/jobs/start`;
export const STOP_TEMP1_JOB_API = `${TEMP1_API}/jobs/stop`;
export const CREATE_MULTIPLE_FILES_WITH_TEMPS_FOR_TIME_INTERVAL_API = `${TEMP1_API}/files/create-multiple-formats`;
export const CUSTOM_INTERVALS_TOTAL_TIME_TEMP_API = `${TEMP1_API}/readings/custom`;
export const STOP_CUSTOM_INTERVALS_TOTAL_TIME_TEMP_API = `${TEMP1_API}/readings/custom/stop`;


export const START_END_TEMP_API = `${API_URL}/temp`;
export const DOWNLOAD_EXCEL_BY_TIME_API = `${API_URL}/download-file-with-temp1-temperatures`;

export const USERS_API = `${API_URL}/users`;
export const USER_API = `${API_URL}/user`;

export const USER_LOGIN_API = `${USERS_API}/login`;
export const USER_LOGOUT_API = `${USERS_API}/logout`;
export const USER_SESSION_API = `${USERS_API}/session`;
export const USER_AUTH_STATUS_API = `${USERS_API}/auth-status`;
export const USER_PROFILE_API = `${USERS_API}/profile`;
export const USER_CHANGE_PASSWORD_API = `${USERS_API}/change-password`;
export const REGISTER_USER_API = `${USERS_API}/register`;
export const REFRESH_TOKEN_API = `${USERS_API}/refresh_token`;


export const DOWNLOAD_FILE_API = `${FILES_API}/download-file`;
export const DOWNLOAD_TEMP1_MONTH_API = `${FILES_API}/download-temp1`;
export const LIST_GENERATED_FILES_URL = `${FILES_API}/list-generated-files`;

export const JOB_CREATE_EXCEL_API = `${CRON_API}/job-create-excel`;
export const START_JOB_CREATE_EXCEL_API = `${CRON_API}/job-create-excel-start`;
export const STOP_JOB_CREATE_EXCEL_API = `${CRON_API}/job-create-excel-stop`;
export const SHOULD_ONSTART_CREATE_EXCEL_JOB_API = `${CRON_API}/job-create-excel-should-onstart`;
