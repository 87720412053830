import React from 'react';
import { Snackbar, Alert } from "@mui/material"
import PropTypes from 'prop-types';

SnackbarAlert.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
    message: PropTypes.string.isRequired,
};

export default function SnackbarAlert({ open, handleClose, severity = 'info', message, autoHideDuration = 6000 }) {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            className='snackbar-alert'
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                elevation={6}
                variant="filled"
                sx={{
                    px: [0.5, 2],
                }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
}